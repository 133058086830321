import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../../assets/IOS logo (white).png';
import '../../App.css';
import './navbar.css';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-padding">
            <div className="navbar-brand">
                <a href="/">
                    <img src={logo} className="App-logo" alt="logo" width="25" />
                </a>
                <a href="/" className="brand-logo-title">TravelMe</a>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                        <a className="nav-link nav-item-custom" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a class="nav-link nav-item-custom" href="/about">About</a>
                    </li>
                    <li className="nav-item">
                        <a class="nav-link nav-item-custom" target='_blank' href="https://travelmeapp.atlassian.net/servicedesk/customer/portal/3">Contact</a>
                    </li>
                    <li className="nav-item">
                        <a class="nav-link nav-item-custom" href="https://apps.apple.com/us/app/travelme/id6736663405">Download</a>
                    </li>
                </ul>
            </div>
            <div className="spacer" style={{width:"132px", height: 10}} />
        </nav>
    );
}

export default Navbar;